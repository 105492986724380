"use client";

import {axiosInstance} from "@/app/api";
import {getNeoCookie} from "@/libs/helper/cookies";

const onGetClientProfile = async (): Promise<NeoClients> => {
  const selectedClient: Client = await getNeoCookie("neo.client");
  const clientProfileResponse: NeoClients = onGetClientProfileResponse();
  return await axiosInstance()
    .get("api/profile")
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<INeoClients> = response.data;
        if (
          neoresponse &&
          neoresponse.data &&
          neoresponse.data?.usersMeta?.length > 0
        ) {
          clientProfileResponse.clients =
            neoresponse.data?.usersMeta?.map(x => {
              return {
                institutionId: x?.insId,
                institutionUserId: x?.userid,
                institutionName: x?.schName,
                name: x?.name,
                roles: [],
                institutionUserName: x?.userName
              };
            }) || [];
          clientProfileResponse.default =
            selectedClient && selectedClient?.institutionId !== null
              ? selectedClient
              : clientProfileResponse.clients[0];
        }
        if (clientProfileResponse?.default?.institutionId) {
          return Promise.all([
            onGetOperators(clientProfileResponse?.default?.institutionId),
            onGetInstVehicles(clientProfileResponse?.default?.institutionId)
            // onGetFacility(clientProfileResponse?.default?.institutionId) // enable once facility api ready
          ]).then(([operatorsResponse, vehiclesResponse]) => {
            clientProfileResponse.lookup.operators =
              operatorsResponse.operators;
            clientProfileResponse.lookup.categories =
              operatorsResponse.categories;
            clientProfileResponse.lookup.vehicles = vehiclesResponse.vehicles;
            // clientProfileResponse.lookup.facility = vehiclesResponse.facility  // enable once facility api ready
            return clientProfileResponse;
          });
        }
        return clientProfileResponse;
      }
      return clientProfileResponse;
    })
    .catch((error: any) => {
      return clientProfileResponse;
    });
};

const onGetInstVehicles = async (instituteId: number): Promise<NeoLookup> => {
  return await axiosInstance()
    .get(`/api/vehicles?insId=${instituteId}`)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<VehicleInfo[]> = response.data;
        return {
          vehicles: neoresponse.data ?? []
        };
      } else {
        return {
          vehicles: []
        };
      }
    })
    .catch((error: any) => {
      return {
        vehicles: []
      };
    });
};

// skeleton for facility just change the api endpoint and response path here
const onGetFacility = async (instituteId: number): Promise<NeoLookup> => {
  return await axiosInstance()
    .get(`/api/vehicles?insId=${instituteId}`)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<Facility[]> = response.data;
        return {
          facility: neoresponse.data ?? []
        };
      } else {
        return {
          facility: []
        };
      }
    })
    .catch((error: any) => {
      return {
        facility: []
      };
    });
};

const onGetOperators = async (instituteId: number): Promise<NeoLookup> => {
  return await axiosInstance()
    .get(`/api/operators?instituteId=${instituteId}`)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<NeoLookup> = response.data;
        return {
          operators: neoresponse.data?.operators ?? [],
          categories: neoresponse.data?.categories ?? []
        };
      } else {
        return {
          operators: [],
          categories: []
        };
      }
    })
    .catch((error: any) => {
      return {
        operators: [],
        categories: []
      };
    });
};

export const metaService = {
  onGetClientProfile
};

const onGetClientProfileResponse = () => {
  return {
    clients: [],
    default: {
      institutionId: null,
      institutionUserId: null,
      institutionName: null,
      name: null,
      roles: [],
      institutionUserName: null
    },
    lookup: {
      operators: [],
      categories: []
    }
  } as NeoClients;
};
