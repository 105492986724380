export const LiveFilterConfig = {
  vehicle: [
    {
      label: "No vehicle",
      value: "1"
    }
  ],
  Driver: [
    {
      label: "Jhon Doe",
      value: "1"
    },
    {
      label: "Maxwell",
      value: "2"
    }
  ]
};
