export const LiveConfig: LivePageConfig = {
  title: "Live",
  playBackType: [
    {
      id: 1,
      key: 1,
      label: "Maps",
      status: "maps"
    }
    // {
    //   id: 2,
    //   key: 2,
    //   label: "Timeline",
    //   status: "timeline"
    // }
  ],
  statusType: [
    {
      id: 1,
      key: 0,
      label: "All Vehicles",
      status: "all",
      count: 0
    },
    {
      id: 2,
      key: 1,
      label: "In-Progress Trips",
      status: "in-progress",
      count: 0
    },
    {
      id: 3,
      key: 2,
      label: "Completed Trips",
      status: "completed",
      count: 0
    },
    {
      id: 4,
      key: 3,
      label: "Not in Trip",
      status: "off-trip",
      count: 0
    }
  ],
  routes: [
    {
      label: "ROUTE_XR_ZU",
      value: "1"
    },
    {
      label: "ROUTE_XR_Z",
      value: "2"
    }
  ],
  chips: [
    {
      label: "5m+",
      value: "danger"
    },
    {
      label: "3m+",
      value: "warning"
    },
    {
      label: "<3m",
      value: "primary"
    },
    {
      label: "Stop",
      value: undefined,
      image: "/images/boarding_change.png"
    }
  ]
};

export const TimeLineConfig = [
  {
    name: "Auto Start BP",
    updatedDate: "24-10-2023 | 18:06:51",
    isActive: true,
    students: [
      {
        name: "Arunkumar",
        swipe:true
      },
      {
        name: "Velumani",
        swipe:false
      },
      {
        name: "mullai",
        swipe:true
      }
    ]
  },
  {
    name: "Bp 1",
    updatedDate: "24-10-2023 18:06:51 ",
    isActive: false,
    students: [
      {
        name: "Senthil",
        swipe:true
      },
      {
        name: "Velumani",
        swipe:false
      },
      {
        name: "mullai",
        swipe:true
      }
    ]
  }
];

export const BusIcon = `
<svg width="28" height="30" viewBox="0 0 47 48" fill="%color%" xmlns="http://www.w3.org/2000/svg">
<path d="M33.8258 44.5324C33.8258 45.8471 34.8913 46.9162 36.206 46.9162C37.5208 46.9162 38.5863 45.8471 38.5863 44.5324V34.8812C38.5863 33.5701 37.5208 32.501 36.206 32.501C34.8913 32.501 33.8258 33.5701 33.8258 34.8812V44.5324ZM12.5586 44.5324C12.5586 45.8471 11.4931 46.9162 10.1784 46.9162C8.86362 46.9162 7.7981 45.8471 7.7981 44.5324V34.8812C7.7981 33.5701 8.86362 32.501 10.1784 32.501C11.4931 32.501 12.5586 33.5701 12.5586 34.8812V44.5324Z" fill="#2F2F2F"/>
<path d="M41.2047 7.02965C41.165 6.26031 40.6376 5.23452 40.0345 4.75413C40.0345 4.75413 35.3498 1.00854 22.95 1.00854C10.5502 1.00854 6.31335 4.70717 6.31335 4.70717C5.73183 5.21646 5.22254 6.26392 5.18281 7.03327L4.20036 25.3675C4.16063 26.1404 4.12451 27.401 4.12451 28.174V39.6491C4.12547 40.0215 4.2738 40.3783 4.53709 40.6416C4.80038 40.9049 5.15721 41.0532 5.52956 41.0542H40.8616C41.2339 41.0532 41.5908 40.9049 41.8541 40.6416C42.1173 40.3783 42.2657 40.0215 42.2666 39.6491V28.1667C42.2666 27.3938 42.2341 26.1332 42.1908 25.3603L41.2047 7.02965Z" fill="inherit"/>
<path d="M12.9885 34.2203C12.9885 35.6903 11.7894 36.8895 10.3157 36.8895C8.83843 36.8895 7.64648 35.6903 7.64648 34.2203C7.64648 32.7466 8.84204 31.551 10.3157 31.551C11.793 31.551 12.9885 32.7466 12.9885 34.2203ZM33.5694 34.2203C33.5694 35.6903 34.765 36.8895 36.2422 36.8895C37.7159 36.8895 38.9115 35.6903 38.9115 34.2203C38.9115 32.7466 37.7159 31.551 36.2422 31.551C34.765 31.551 33.5694 32.7466 33.5694 34.2203Z" fill="white"/>
<path d="M38.329 12.5233C38.2893 10.981 36.989 9.7168 35.4467 9.7168H10.9396C9.39373 9.7168 8.09704 10.981 8.05731 12.5233L7.74307 24.244C7.73257 24.6094 7.79558 24.9731 7.92835 25.3136C8.06113 25.654 8.26097 25.9644 8.516 26.2262C8.77104 26.4879 9.07607 26.6958 9.41299 26.8374C9.74991 26.979 10.1118 27.0515 10.4773 27.0505H35.9162C37.4621 27.0505 38.6902 25.7863 38.6505 24.244L38.329 12.5233Z" fill="#40C0E7"/>
<path d="M31.9509 7.57491H13.9381C13.0712 7.57491 12.3633 6.87058 12.3633 6.0001C12.3633 5.12962 13.0712 4.42529 13.9381 4.42529H31.9509C32.8213 4.42529 33.522 5.12962 33.522 6.0001C33.5229 6.20683 33.4828 6.41168 33.4042 6.60287C33.3255 6.79407 33.2099 6.96783 33.0639 7.11418C32.9179 7.26053 32.7444 7.37658 32.5534 7.45564C32.3623 7.53471 32.1576 7.57524 31.9509 7.57491Z" fill="white"/>
</svg>
`;

export const PlaybackSpeedConfig = [
  {
    speedFactor: 0.5,
    value: 2500
  },
  {
    speedFactor: 1,
    value: 1000
  },
  {
    speedFactor: 1.5,
    value: 750
  },
  {
    speedFactor: 2,
    value: 500
  },
  {
    speedFactor: 2.5,
    value: 250
  },
  {
    speedFactor: 3,
    value: 200
  }
];
