export const DriverViewConfig: DriverViewConfig = {
  title: "Drivers",
  cards: [
    {
      title: "Driver Details",
      type: "driver",
      userId: "",
      status: "Active",
      fields: [
        {
          label: "Name",
          value: "NA",
          type: "text",
          key: "name",
          isVisible: false
        },
        {
          label: "Phone No",
          value: "NA",
          type: "text",
          key: "phone",
          isVisible: true
        },
        {
          label: "Email",
          value: "NA",
          type: "text",
          key: "email",
          isVisible: true
        },
        {
          label: "Emergency Contact No",
          value: "NA",
          type: "text",
          key: "emergencyNo",
          isVisible: true
        },
        {
          label: "RFID",
          value: "NA",
          type: "text",
          key: "rfidStatus",
          isVisible: true
        },
        {
          label: "RFID No",
          value: "NA",
          type: "text",
          key: "rfid",
          isVisible: true
        },
        {
          label: "Start Date",
          value: "NA",
          type: "text",
          key: "",
          isVisible: false
        },
        {
          label: "End Date",
          value: "NA",
          type: "text",
          key: "name",
          isVisible: false
        },
        {
          label: "Facility",
          value: "NA",
          type: "text",
          key: "facility",
          isVisible: true
        },
        {
          label: "Employment Type",
          value: "NA",
          type: "text",
          key: "employmentType",
          isVisible: true
        },
        {
          label: "Operator",
          value: "NA",
          type: "text",
          key: "operatorId",
          isVisible: true
        },
        {
          label: "Vendor ID",
          value: "NA",
          type: "text",
          key: "vendorId",
          isVisible: true
        },
        {
          label: "Status",
          value: "NA",
          type: "text",
          key: "status",
          isVisible: false
        }
      ]
    },
    {
      title: "Compliance Details",
      type: "compliance",
      fields: [
        {
          label: "License No",
          value: "NA",
          type: "text",
          key: "licenceNo",
          isVisible: true
        },
        {
          label: "Licence Expiry",
          value: "NA",
          type: "date",
          key: "licenceExpiry",
          isVisible: true
        },
        {
          label: "Badge No",
          value: "NA",
          type: "text",
          key: "badgeNo",
          isVisible: true
        },
        {
          label: "Aadhar No",
          value: "NA",
          type: "text",
          key: "aadharNo",
          isVisible: true
        },
        {
          label: "Medical Test Expiry",
          value: "NA",
          type: "date",
          key: "medTestExpiry",
          isVisible: true
        },
        {
          label: "DDT Certificate No",
          value: "NA",
          type: "text",
          key: "ddtCertNo",
          isVisible: true
        },
        {
          label: "DDT Expiry",
          value: "NA",
          type: "date",
          key: "ddtExpiry",
          isVisible: true
        },
        {
          label: "BGV Status",
          value: "NA",
          type: "text",
          key: "bgv",
          isVisible: true
        },
        {
          label: "BGV Completed on",
          value: "NA",
          type: "date",
          key: "bgvComplDate",
          isVisible: true
        },
        {
          label: "Induction Status",
          value: "NA",
          type: "text",
          key: "induction",
          isVisible: true
        },
        {
          label: "Induction Completed on",
          value: "NA",
          type: "date",
          key: "inductionDate",
          isVisible: true
        }
      ]
    }
  ]
};
