/**
 * ITrackerStatus
 *
 * Back end status for gps tracker
 *
 * @enum {type} MOVING  - Vehicle is moving
 * @enum {type} OFFLINE -  Vehicle is offline
 * @enum {type} STATIONARY - Vehicle is stationary
 */

export enum ITrackerStatus {
  MOVING = 0,
  OFFLINE = 1,
  STATIONARY = 2
}

/**
 * IStatusType
 *
 * Back end status for gps tracker
 *
 * @enum {type} ONTRIP  - Planned or unplanned trip
 * @enum {type} OFFTRIP -  No trips
 * @enum {type} COMPLETEDTRIP - Completed trips
 */

export enum IStatusType {
  ONTRIP = 1,
  OFFTRIP = 2,
  COMPLETEDTRIP = 4
}

/**
 * StatusType
 *
 * Describes about the live status of vehicles in live screen
 *
 * @enum {type} ALL
 * @enum {type} INPROGRESS
 * @enum {type} COMPLETED
 * @enum {type} NOTINTRIP
 */

export enum StatusType {
  ALL = 0,
  INPROGRESS = 1,
  COMPLETED = 2,
  NOTINTRIP = 3
}
/**
 * GPSTrackerStatus
 *
 * Describes about the vehicle Gps status  in live screen
 *
 * @enum {type} MOVING  - Vehicle is on trip either planned or unplanned
 * @enum {type} OFFLINE - Vehicle is offline
 * @enum {type} STATIONARY - Vehicle is parked
 * @enum {type} DEAD - Vehicle does not send any information
 */

export enum GPSTrackerStatus {
  MOVING = 0,
  OFFLINE = 1,
  STATIONARY = 2,
  DEAD = 3
}

/**
 * TripStatus
 *
 * Describes about the trip type in live screen
 *
 * @enum {type} PLANNED  - Planned trip which has a route
 * @enum {type} UNPLANNED - Unplanned trip which does not have a route
 * @enum {type} COMPLETED - Completed trip which have a route
 * @enum {type} NOTRIP - Offline/dead/parked trip which does not have a route
 */

export enum SYNC {
  START = 1,
  STOP = 2
}

export enum TripStatus {
  PLANNED = 0,
  UNPLANNED = 1,
  COMPLETED = 3,
  NOTRIP = 4
}

export enum PlayBackType {
  MAPS = 1,
  TIMELINE = 2
}

export enum PageType {
  LIVE = 1,
  PLAYBACK = 2
}

export enum GPSTracker {
  NEUTRAL = 0,
  START = 1,
  STOP = 2,
  PLAYBACK = 3
}

export enum PlayAction {
  DECREASE = 0,
  INCREASE = 1
}

export enum ActiveStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  VACATION = "Vacation"
}

export enum PaginationAction {
  FORWARD = 0,
  BACKWARD = 1
}
