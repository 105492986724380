export const VehicleViewConfig: VehicleViewConfig = {
  cards: [
    {
      title: "Vehicle Details",
      type: "vehicle",
      fields: [
        {
          label: "Short Name",
          value: "NA",
          type: "text",
          key: "shortName",
          isVisible: false
        },
        {
          label: "Ownership",
          value: "NA",
          type: "text",
          key: "agencyName",
          isVisible: true
        },
        {
          label: "GPS System",
          value: "NA",
          type: "text",
          key: "gps",
          isVisible: true
        },
        {
          label: "GPS",
          value: "NA",
          type: "text",
          key: "gpstype",
          isVisible: true
        },
        {
          label: "Hardware No",
          value: "NA",
          type: "text",
          key: "trackerId",
          isVisible: true
        },
        {
          label: "Vehicle Type",
          value: "NA",
          type: "text",
          key: "type",
          isVisible: true
        },
        {
          label: "Fuel Type",
          value: "NA",
          type: "text",
          key: "fueltype",
          isVisible: true
        },
        {
          label: "Vehicle Category",
          value: "NA",
          type: "text",
          key: "category",
          isVisible: true
        },
        {
          label: "Seating Capacity",
          value: "NA",
          type: "text",
          key: "capacity",
          isVisible: true
        },
        {
          label: "Operator",
          value: "NA",
          type: "text",
          key: "operator",
          isVisible: true
        },
        {
          label: "Contract Start Date",
          value: "NA",
          type: "date",
          key: "contractstartdate",
          isVisible: true
        },
        {
          label: "Contract End Date",
          value: "NA",
          type: "date",
          key: "contractenddate",
          isVisible: true
        },
        {
          label: "Driver Name",
          value: "NA",
          type: "text",
          key: "driver",
          isVisible: true
        },
        {
          label: "Driver Phone No",
          value: "NA",
          type: "text",
          key: "driverphoneno",
          isVisible: true
        },
        {
          label: "Max Travel Duration(HH:MM)",
          value: "NA",
          type: "text",
          key: "maxtraveltime",
          isVisible: true
        },
        {
          label: "Max Travel Distance",
          value: "NA",
          type: "text",
          key: "maxtraveldistance",
          isVisible: true
        },
        {
          label: "Starting KMS",
          value: "NA",
          type: "text",
          key: "startingKms",
          isVisible: true
        },
        {
          label: "Vehicle Purchased Date",
          value: "NA",
          type: "date",
          key: "vehiclepurchaseddate",
          isVisible: true
        },
        {
          label: "Colour Palatte",
          value: "NA",
          type: "text",
          key: "colour",
          isVisible: true
        },
        {
          label: "Released Date",
          value: "NA",
          type: "date",
          key: "releaseddate",
          isVisible: true
        }
      ]
    },
    {
      title: "Compliance Details",
      type: "compliance",
      fields: [
        {
          label: "Registration Certificate No",
          value: "NA",
          type: "text",
          key: "rcnumber",
          isVisible: true
        },
        {
          label: "Registration Certificate Expiry",
          value: "NA",
          type: "date",
          key: "rcexpiryDate",
          isVisible: true
        },
        {
          label: "Pollution Certificate No",
          value: "NA",
          type: "text",
          key: "pollutionCertificateNo",
          isVisible: true
        },
        {
          label: "Pollution Expiry",
          value: "NA",
          type: "date",
          key: "pollutionExpiryDate",
          isVisible: true
        },
        {
          label: "First Party Insurance No",
          value: "NA",
          type: "text",
          key: "firstPartyInsuranceNo",
          isVisible: true
        },
        {
          label: "First Party Insurance Expiry",
          value: "NA",
          type: "date",
          key: "firstPartyInsuranceExpiryDate",
          isVisible: true
        },
        {
          label: "Thirty Party Insurance No",
          value: "NA",
          type: "text",
          key: "thirdPartyInsuranceNo",
          isVisible: true
        },
        {
          label: "Thirty Party Insurance Expiry",
          value: "NA",
          type: "date",
          key: "thirdPartyInsuranceExpiryDate",
          isVisible: true
        },
        {
          label: "Permit No",
          value: "NA",
          type: "text",
          key: "permitNo",
          isVisible: true
        },
        {
          label: "Permit Expiry",
          value: "NA",
          type: "date",
          key: "permitExpiryDate",
          isVisible: true
        },
        {
          label: "Fitness Expiry",
          value: "NA",
          type: "date",
          key: "fitnessExpiryDate",
          isVisible: true
        },
        {
          label: "Road Tax Expiry",
          value: "NA",
          type: "date",
          key: "roadTaxExpiryDate",
          isVisible: true
        },
        {
          label: "Last Service Kms",
          value: "NA",
          type: "text",
          key: "lastServiceKms",
          isVisible: true
        },
        {
          label: "Last Service On",
          value: "NA",
          type: "date",
          key: "lastServiceDate",
          isVisible: true
        },
        {
          label: "Last Tyre Change Date",
          value: "NA",
          type: "date",
          key: "lastTyreChangedDate",
          isVisible: true
        }
      ]
    }
  ]
};
