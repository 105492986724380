import CryptoJS from "crypto-js";
import {GPSTrackerStatus, IStatusType, StatusType} from "../enums/live";
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";

export const isNumber = (value: any): value is number => {
  return typeof value === "number";
};

export const isString = (value: any): value is string => {
  return typeof value === "string";
};

export const encrypt = async (data?: string, key?: string) => {
  if (data && key) {
    const encrypted = await CryptoJS.AES.encrypt(data, key);
    return encrypted.toString();
  }
  return null;
};

export const decrypt = async (ciphertext?: string, key?: string) => {
  if (ciphertext && key) {
    const decrypted = await CryptoJS.AES.decrypt(ciphertext, key);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  return null;
};

export const encryptUrl = async (data?: string, key?: string) => {
  if (data && key) {
    const encrypted = await CryptoJS.AES.encrypt(data, key).toString();
    const ciphertext = CryptoJS.enc.Base64.parse(encrypted);
    return CryptoJS.enc.Hex.stringify(ciphertext);
  }
  return null;
};

export const decryptUrl = async (ciphertext?: string, key?: string) => {
  if (ciphertext && key) {
    const decrypted = CryptoJS.enc.Hex.parse(ciphertext).toString(
      CryptoJS.enc.Base64
    );
    const deciphertext = await CryptoJS.AES.decrypt(decrypted, key);
    return deciphertext.toString(CryptoJS.enc.Utf8);
  }
  return null;
};

export const convertMTOKM = (m: any) => {
  return m && m != 0 ? (m / 1000).toFixed(2) + " km" : "0 km";
};

export const fetchFilterStatus = (s: IStatusType) => {
  switch (s) {
    case IStatusType.COMPLETEDTRIP:
      return StatusType.COMPLETED;
    case IStatusType.ONTRIP:
      return StatusType.INPROGRESS;
    case IStatusType.OFFTRIP:
      return StatusType.NOTINTRIP;
  }
};

export const fetchCurrentStatus = (g: GPSTrackerStatus, details: Details) => {
  switch (g) {
    case GPSTrackerStatus.MOVING:
      return "Moving";
    case GPSTrackerStatus.OFFLINE:
      return "Offline";
    case GPSTrackerStatus.DEAD:
      return "Unknown";
    case GPSTrackerStatus.STATIONARY:
      return details?.location?.ti
        ? "Stationary" + calcTime(details?.location?.ti, "short")
        : "Stationary";
  }
};

export const fetchTripStatus = (
  g: GPSTrackerStatus,
  hasRoute: boolean,
  details: Details
) => {
  switch (g) {
    case GPSTrackerStatus.MOVING:
      return hasRoute
        ? `Planned trip on route ${details?.onTripData?.routeName}`
        : `Unplanned trip`;
    case GPSTrackerStatus.OFFLINE:
      return `Offline since ${details?.location?.ti ? calcTime(details?.location?.ti) : "unknown"}`;
    case GPSTrackerStatus.DEAD:
      return "Have not received any data from this tracker";
    case GPSTrackerStatus.STATIONARY:
      return `Stationary since ${details?.location?.ti ? calcTime(details?.location?.ti) : "unknown"}`;
  }
};

export const calcTime = (time: number, type?: string) => {
  const currentDate = new Date();
  const providedDate = new Date(time);
  const timeDifference = (new Date().getTime() - time) / 1000;
  const units = ["second", "minute", "hour", "day"];
  const values = [60, 60, 24];

  const unitIndex = Math.max(
    0,
    ...values.map((value, index) => (timeDifference >= value ? index + 1 : 0))
  );
  const roundedDifference = Math.floor(
    timeDifference /
      values.slice(0, unitIndex).reduce((acc, val) => acc * val, 1)
  );
  const unit = units[unitIndex] + (roundedDifference !== 1 ? "s" : "");
  if (type === "short") {
    if (
      unit === "minutes" ||
      unit === "minute" ||
      unit === "second" ||
      unit === "seconds"
    ) {
      const secondsDifference = Math.floor(timeDifference % 60);
      return secondsDifference > 0
        ? `${roundedDifference}m ${secondsDifference}s`
        : `${roundedDifference}m`;
    }
    return "";
  } else {
    if (unit === "day" || unit === "days") {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      if (yesterday.toDateString() === providedDate.toDateString()) {
        return `yesterday ${formatTime(providedDate)}`;
      }
    }
    return `${formatDateTime(providedDate)}`;
  }
};

export const formatTime = (dt: any) => {
  return new Date(dt).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
};

export const formatDateTime = (dt: any) => {
  return new Date(dt)
    .toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
      // timeZone: "IST"
    })
    .replace(/\//g, "-");
};

export const convertTOPeriod = (t: any) => {
  return t && t != 0
    ? new Date(t).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
      ? formatDateTime(t)
      : `since ${formatDateTime(t)}`
    : "NA";
};

export const onGetTableResponse = () => {
  const result: TableResponse<any> = {};
  (result.columns = []),
    (result.rows = []),
    (result.meta = {} as Meta),
    (result.errors = "No result Found");
  return result;
};

export const mapFields = <T>(data: T[], config: TNeoField[]): TNeoField[] => {
  return data
    .map(item => {
      return config.map(field => {
        return {
          label: field.label,
          value:
            field.key &&
            (item as any)[field?.key] != null &&
            (item as any)[field?.key] !== ""
              ? (item as any)[field?.key]?.toString()
              : field.value,
          key: field.key,
          type: field.type,
          isVisible: field?.isVisible
        };
      });
    })
    .flat();
};

export const formatDate = (date: Date): string => {
  // Example format: "YYYY-MM-DD"
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const day = date.getDate().toString().padStart(2, "0");

  // Adjust the format string according to your needs
  return `${year}-${month}-${day}`;
};

export const navigateToEditPage = async (
  entity: string,
  entityId: string,
  entityPath: string
) => {
  const encryptedId = await encryptUrl(entityId, sKey);
  const route = `/${entity}/${encryptedId}/edit/${entityPath}`;

  return route;
};
