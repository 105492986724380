export const VehicleTableConfig: NeoTableConfig = {
  title: "Vehicles",
  table: {
    columns: [
      {name: "Short Name", uid: "shortName", type: "value", sortable: true},
      {
        name: "Registration No",
        uid: "vehicleName",
        type: "value",
        sortable: true
      },
      {name: "Type", uid: "type", type: "value"},
      {name: "Category", uid: "category", type: "value"},
      {name: "Operator", uid: "operator", type: "value"},
      {name: "Driver Name", uid: "driverName", type: "value"},
      {name: "GPS", uid: "gps", type: "value"},
      {name: "Facility", uid: "facility", type: "value"},
      {name: "Status", uid: "status", type: "status"},
      {name: "Actions", uid: "actions", type: "actions"}
    ],
    activeColumns: [
      "shortName",
      "vehicleName",
      "type",
      "category",
      "operator",
      "facitlity",
      //"actions"
    ],
    style: {
      height: "h-full"
    },
    meta: {
      id: 2,
      title: "Manage Vehicles",
      description: "Total Vehicles",
      hasSearch: true,
      hasUpload: false,
      hasDownload: false,
      hasAdd: false,
      hasActions: true,
      hasCheckbox: false,
      hasFilter: true,
      hasFooter: true,
      hasHeader: true,
      hasPagination: true,
      hasCreate: false
    },
    url: {
      page: {
        view: "vehicles/${navId}/view/vehicle-details",
        add: "vehicles/new/create/vehicle",
        edit: "vehicles/${navId}/edit/vehicle"
      },
      api: {
        upload: "/api/vehicles/upload",
        download: "/api/vehicles/download",
        delete: "/api/vehicles/delete",
        get: "/api/vehicles/list"
      }
    },
    actions: [
      {
        key: "edit",
        label: "Edit",
        type: "navigate",
        isVisible: true
      },
      // we can enable for phase 2
      {
        key: "delete",
        label: "Delete",
        type: "modal",
        isVisible: false
      },
      {
        key: "deactivate",
        label: "Deactivate",
        type: "modal",
        isVisible: false
      }
    ]
  }
};
