import {ActiveStatus} from "@/libs/enums/live";
import {Chip} from "@nextui-org/react";

interface NeoStatusProps {
  status: ActiveStatus | undefined;
}

const statusColorMap = {
  Active: "success",
  Inactive: "danger",
  Vacation: "warning"
};

export const NeoStatus: React.FC<NeoStatusProps> = ({status}) => {
  return (
    <Chip
      className="capitalize"
      color={(statusColorMap as any)[status ? status : "success"]}
      size="sm"
      variant="flat"
    >
      {status}
    </Chip>
  );
};
