import {parseCookies, setCookie} from "nookies";
import {decrypt, encrypt} from "./util";

const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";

export const setNeoCookie = async (key: string, data: any) => {
  const content = await encrypt(JSON.stringify(data), sKey);
  if (content) {
    setCookie(null, key, content, {
      path: "/"
    });
  }
};

export const getNeoCookie = async (key: any) => {
  try {
    const cookies = parseCookies(null);
    const content = await decrypt(cookies[key], sKey);
    if (content) {
      return JSON.parse(content) ? JSON.parse(content) : content;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Cannot parse, invalid input:", error);
  }
};
