export const NeoOption: React.FC<any> = ({data, cellValue}) => {
  const booleanValue =
    typeof cellValue === "boolean" ? cellValue : cellValue === 1;
  return (
    <div className="flex flex-col">
      <p className="text-bold text-small capitalize">
        {booleanValue ? "Yes" : "No"}
      </p>
    </div>
  );
};
