export const UserFormConfig = {
  institutionUserId: "",
  institutionId: "",
  userId: "",
  user: {
    name: "",
    gender: "",
    parentName: "",
    phoneNumber: "",
    email: "",
    emergencyContactNo: "",
    destination: "",
    rfid: false,
    rfidNo: "",
    startDate: "",
    endDate: "",
    userRole: "",
    userType: "",
    admissionNo: "",
    className: "",
    vehicles: []
  },
  student: {
    userId: "",
    studentName: "",
    studentId: "",
    className: "",
    sectionName: "",
    admissionNo: "",
    pickup: "",
    drop: ""
  },
  employee: {
    employeeId: "",
    empDepartment: "",
    destination: "",
    employeeType: "",
    agency: "",
    category: "",
    shift: "",
    pickup: "",
    drop: ""
  }
};
