import {axiosInstance} from "@/app/api";
import {DriverViewConfig} from "@/data";
import {DriverFormConfig} from "@/data/drivers/form.config";
import {formatDate, mapFields, onGetTableResponse} from "@/libs/helper";

const onUpdateDriverDetails = async (
  driverId: any,
  filterContext: any,
  data: any
): Promise<any> => {
  const body = {
    userId: driverId,
    insId: filterContext?.institutionId,
    loginUserId: filterContext?.institutionUserId,
    ...data
  };
  return await axiosInstance().put("/api/driver/update", body);
};

const onCreateDriverDetails = async (
  filterContext: any,
  data: any
): Promise<any> => {
  const body = {
    loginUserId: filterContext?.institutionUserId,
    insId: filterContext?.institutionId,
    ...data
  };
  return await axiosInstance()
    .post("/api/driver/create", body)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      throw error;
    });
};

const onSaveComplainceDetails = async (
  driverId: any,
  additionalPrimaryData: any,
  data: any
): Promise<any> => {
  const DateFieldList = DriverViewConfig.cards
    .find(x => x?.type == "compliance")
    ?.fields?.filter(x => x?.type == "date");
  DateFieldList?.map(x => {
    data[x.key] && (data[x.key] = formatDate(data[x.key]));
  });
  const body = {
    userId: driverId,
    insId: additionalPrimaryData?.insId,
    loginUserId: additionalPrimaryData?.currentUser,
    ...data
  };
  return await axiosInstance().put("/api/driver/update/compliance", body);
};

const onGetDriverDetails = async (id: string): Promise<any> => {
  const driverView = DriverViewConfig;
  const DriverFields = driverView.cards.find(x => x?.type == "driver")?.fields;
  const ComplianceFields = driverView.cards.find(
    x => x?.type == "compliance"
  )?.fields;

  return await axiosInstance()
    .get(`/api/driver/read?driverId=${id}`)
    .then((response: any) => {
      if (response.data.status === "success") {
        const driverFields = mapFields(
          [response?.data?.data],
          DriverFields as TNeoField[]
        );
        const complainceFields = mapFields(
          [response?.data?.data],
          ComplianceFields as TNeoField[]
        );
        driverView.cards[0].fields = driverFields;
        driverView.cards[1].fields = complainceFields;
        return driverView;
      }
      return driverView;
    })
    .catch((error: any) => {
      return driverView;
    });
};

const onFetchDriverDetails = async (
  id: string,
  action: string,
  slug: string
): Promise<any> => {
  const formView = DriverFormConfig;
  if (action === "create") {
    return {
      driverId: "",
      data: slug === "drivers" ? formView.driver : formView.compliance
    };
  }
  return await axiosInstance()
    .get(`/api/driver/read?driverId=${id}`)
    .then((response: any) => {
      const data = response.data.data;
      if (response.data.status === "success") {
        if (slug === "driver") {
          formView.driver.name = data?.name;
          formView.driver.phone = data?.phone;
          formView.driver.email = data?.email;
          formView.driver.rfidStatus = data?.rfid !== "" ? true : false;
          formView.driver.rfid = data?.rfid;
          formView.driver.operatorId = data?.operatorId;
          formView.driver.vendorId = data?.vendorId;
          formView.driver.emergencyNo = data?.emergencyNo;
          return {
            driverId: data?.userId,
            data: formView.driver
          };
        } else {
          formView.compliance.licenceNo = data?.licenceNo;
          formView.compliance.licenceExpiry = data?.licenceExpiry;
          formView.compliance.badgeNo = data?.badgeNo;
          formView.compliance.aadharNo = data?.aadharNo;
          formView.compliance.medTestExpiry = data?.medTestExpiry;
          formView.compliance.ddtCertNo = data?.ddtCertNo;
          formView.compliance.ddtExpiry = data?.ddtExpiry;
          formView.compliance.bgv = data?.bgv;
          formView.compliance.bgvComplDate = data?.bgvComplDate;
          formView.compliance.inductionStatus = data?.induction;
          formView.compliance.inductionDate = data?.inductionDate;
          return {
            driverId: data?.userId,
            data: formView.compliance
          };
        }
      } else {
        return {
          driverId: data?.userId,
          data: formView.compliance
        };
      }
    })
    .catch((error: any) => {
      return {
        driverId: id,
        data: slug === "drivers" ? formView.driver : formView.compliance
      };
    });
};

const onDriverAction = async (
  url: string,
  institutionUserId: string | null,
  actionType: string,
  payload: any
): Promise<any> => {
  let body;

  switch (actionType) {
    case "delete":
      body = {userId: institutionUserId};
      break;
    case "deactivate":
      body = {userId: institutionUserId, status: 0}; // if deactivate it should be 0
      break;
    default:
      body = payload;
      break;
  }
  return await axiosInstance()
    .put(url, body)
    .then((response: any) => {
      return response; // returning status and message
    })
    .catch((error: any) => {
      throw error;
    });
};

const onGetDrivers = async (url: string, body: any): Promise<any> => {
  return await axiosInstance()
    .post(url, body)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<IDrivers> = response.data;
        const rows = neoresponse?.data?.drivers?.map(row => ({
          ...row,
          id: row.userId
        }));
        return {
          columns: neoresponse?.data?.columns,
          rows: rows,
          meta: neoresponse?.meta
        } as TableResponse<IDriverList>;
      }
      return onGetTableResponse();
    })
    .catch((error: any) => {
      return onGetTableResponse();
    });
};

export const driversService = {
  onUpdateDriverDetails,
  onSaveComplainceDetails,
  onGetDrivers,
  onGetDriverDetails,
  onFetchDriverDetails,
  onCreateDriverDetails,
  onDriverAction
};
