export const UserViewConfig: UserViewConfig = {
  cards: [
    {
      title: "User Details",
      type: "user",
      userId: "",
      status: "Active",
      fields: [
        {
          label: "Name",
          value: "NA",
          type: "text",
          key: "name",
          isVisible: true
        },
        {
          label: "Gender",
          value: "NA",
          type: "text",
          key: "gender",
          isVisible: true
        },
        {
          label: "Parent Name",
          value: "NA",
          type: "text",
          key: "parentName",
          isVisible: true
        },
        {
          label: "Phone No",
          value: "NA",
          type: "text",
          key: "phone",
          isVisible: true
        },
        {
          label: "Email",
          value: "NA",
          type: "text",
          key: "email",
          isVisible: true
        },
        {
          label: "Emergency Contact No",
          value: "NA",
          type: "text",
          key: "contactNo",
          isVisible: true
        },
        {
          label: "Destination",
          value: "NA",
          type: "text",
          key: "destination",
          isVisible: true
        },
        {
          label: "RFID",
          value: "NA",
          type: "text",
          key: "rfid",
          isVisible: true
        },
        {
          label: "RFID No",
          value: "NA",
          type: "text",
          key: "rfidNo",
          isVisible: true
        },
        {
          label: "Start Date",
          value: "NA",
          type: "date",
          key: "startDate",
          isVisible: false
        },
        {
          label: "End Date",
          value: "NA",
          type: "date",
          key: "endDate",
          isVisible: false
        },
        {
          label: "User Role",
          value: "NA",
          type: "date",
          key: "role",
          isVisible: true
        },
        {
          label: "User Type",
          value: "NA",
          type: "date",
          key: "type",
          isVisible: true
        }
      ]
    },
    {
      title: "Employee Details",
      type: "employee",
      fields: [
        {
          label: "Employee ID",
          value: "NA",
          type: "text",
          key: "employeeId",
          isVisible: true
        },
        {
          label: "Department",
          value: "NA",
          type: "text",
          key: "empDept",
          isVisible: true
        },
        {
          label: "Designation",
          value: "NA",
          type: "text",
          key: "department",
          isVisible: true
        },
        {
          label: "Employment Type",
          value: "NA",
          type: "text",
          key: "employeeType",
          isVisible: true
        },
        {
          label: "Agency",
          value: "NA",
          type: "text",
          key: "agency",
          isVisible: true
        },
        {
          label: "Category",
          value: "NA",
          type: "text",
          key: "category",
          isVisible: true
        },
        {
          label: "Shift",
          value: "NA",
          type: "text",
          key: "shift",
          isVisible: true
        },
        {
          label: "Pick UP",
          value: "NA",
          type: "array",
          key: "pickup",
          isVisible: true
        },
        {
          label: "Drop",
          value: "NA",
          type: "array",
          key: "drop",
          isVisible: true
        }
      ]
    },
    {
      title: "Student Details",
      type: "student",
      fields: [
        {
          label: "Student ID",
          value: "NA",
          type: "text",
          key: "studentId",
          isVisible: true
        },
        {
          label: "Class",
          value: "NA",
          type: "text",
          key: "className",
          isVisible: true
        },
        {
          label: "Section",
          value: "NA",
          type: "text",
          key: "sectionName",
          isVisible: true
        },
        {
          label: "Admission No",
          value: "NA",
          type: "text",
          key: "admissionNo",
          isVisible: true
        },
        {
          label: "Pick UP",
          value: "NA",
          type: "array",
          key: "pickup",
          isVisible: true
        },
        {
          label: "Drop",
          value: "NA",
          type: "array",
          key: "drop",
          isVisible: true
        }
      ]
    }
  ]
};
