export const ProfileData: ProfileModule.Profile = {
  title: "Profile",
  card: {
    title: "Information",
    fields: [
      {
        label: "Username",
        value: "NA",
        key: "userName",
        type: "text"
      },
      {
        label: "User ID",
        value: "NA",
        key: "userid",
        type: "text"
      },
      {
        label: "Name",
        value: "NA",
        key: "name",
        type: "text"
      },
      {
        label: "Mobile Number",
        value: "NA",
        key: "phone",
        type: "phone"
      },
      {
        label: "Email",
        value: "NA",
        key: "email",
        type: "text"
      },
      {
        label: "Work Location",
        value: "NA",
        key: "",
        type: "text"
      }
    ]
  },
  actions: [
    {
      link: "",
      action: "change-password",
      label: "Change Password",
      key: "change-password"
    },
    {
      link: "",
      action: "change-mobile",
      label: "Change Mobile Number",
      key: "change-mobile"
    },
    {
      link: "/profile/edit",
      action: "",
      label: "Edit",
      key: "profile-edit"
    }
  ]
};
