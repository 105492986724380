export const DriverFormConfig = {
  institutionUserId: "",
  institutionId: "",
  driverId: "",
  driver: {
    name: "",
    id: "",
    phone: "",
    email: "",
    emergencyNo: "",
    rfidStatus: false,
    rfid: "",
    startDate: null,
    endDate: null,
    facility: "",
    employmentType: false,
    operatorId: "",
    vendorId: ""
  },
  compliance: {
    licenceNo: "",
    licenceExpiry: "",
    badgeNo: "",
    aadharNo: "",
    medTestExpiry: "",
    ddtCertNo: "",
    ddtExpiry: "",
    bgv: false,
    bgvComplDate: "",
    inductionStatus: false,
    inductionDate: ""
  }
};
