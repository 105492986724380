export const ReportMenu: REPORTMEUSCONFIG = [
  {
    header: "Reports",
    description: "",
    order: 1,
    menus: [
      
      {
        icon: "",
        menu: "Notification",
        menu_description:
          "The Notifications report provides an overview of all notifications within the system, and can be used to monitor system activity during periods of load.",
        url: "/api/notifications/list",
        method: "post",
        filters: ["date"],
        columns: [
          {
            uid: "date",
            name: "Date"
          },
          {
            uid: "name",
            name: "Name"
          },
          {
            uid: "enrollNo",
            name: "Enroll NO"
          },
          {
            uid: "className",
            name: "Class"
          },
          {
            uid: "sectionName",
            name: "Section"
          },
          {
            uid: "uhfId",
            name: "UHF Id"
          },
          {
            uid: "msg",
            name: "Notification Content"
          }
        ],
        activeColumns: [
          "date","name","enrollNo","className","sectionName","uhfId","msg"
        ]
      }
    ]
  }
  // {
  //   header: "Performance Reports",
  //   description: "",
  //   order: 1,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Speed",
  //       menu_description:
  //         "The report can be used to get the speed by each vehicle.",
  //       url: "/api/rfid/singleBus/list",
  //       method: "",
  //       filters: ["date", "vehicleSingle"],
  //       columns: [],
  //       activeColumns: []
  //     },
  //     {
  //       icon: "",
  //       menu: "Distance",
  //       menu_description:
  //         "The report can be used to get the total distance driven by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     },
  //     {
  //       icon: "",
  //       menu: "Overspeed",
  //       menu_description:
  //         "The report can be used to get the detailed overspeed alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     },
  //     {
  //       icon: "",
  //       menu: "Trips",
  //       menu_description:
  //         "This report provides a comprehensive overview of vehicle trips,detailing key performance metrics and statuses. It includes information on the trip's timing, duration, and distance, alongside comparisons with planned schedules and distances.The report tracks each trip's progression, highlighting any deviations or delays, and provides a clear picture of overall trip efficiency and performance.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     },
  //     {
  //       icon: "",
  //       menu: "Acceleration",
  //       menu_description:
  //         "The report can be used to get the detailed sudden acceleration alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     },
  //     {
  //       icon: "",
  //       menu: "Harsh Braking",
  //       menu_description:
  //         "The report can be used to get the detailed harsh braking alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     }
  //   ]
  // },
  // {
  //   header: "Attendance Reports",
  //   description: "",
  //   order: 7,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "All Bus Swipe Report",
  //       menu_description:
  //         "The report can be used to get the users swipes on All vehicles.",
  //       url: "/api/rfid/allBus/list",
  //       method: "GET",
  //       filters: ["date"],
  //       columns: [
  //         {
  //           uid: "date",
  //           name: "Date"
  //         },
  //         {
  //           uid: "trackerName",
  //           name: "Bus"
  //         },
  //         {
  //           uid: "studentName",
  //           name: "Student Name"
  //         },
  //         {
  //           uid: "studentClass",
  //           name: "Class"
  //         },
  //         {
  //           uid: "rfid",
  //           name: "Ref. Id/Admission No"
  //         },
  //         {
  //           uid: "onwardPick",
  //           name: "Onward Pickup"
  //         },
  //         {
  //           uid: "onwardDrop",
  //           name: "Onward Drop"
  //         },
  //         {
  //           uid: "returnPick",
  //           name: "Return Pickup"
  //         },
  //         {
  //           uid: "returnDrop",
  //           name: "Return Drop"
  //         }
  //       ],
  //       activeColumns: [
  //         "date",
  //         "trackerName",
  //         "studentName",
  //         "studentClass",
  //         "rfid",
  //         "onwardPick",
  //         "onwardDrop",
  //         "returnPick",
  //         "returnDrop"
  //       ]
  //     },
  //     {
  //       icon: "",
  //       menu: "Vehicle Report",
  //       menu_description:
  //         "The report can be used to get the users swipes on selected vehicle(s).",
  //       url: "/api/rfid/singleBus/list",
  //       method: "POST",
  //       filters: ["date", "vehicleSingle"],
  //       columns: [
  //         {
  //           uid: "date",
  //           name: "Date"
  //         },
  //         {
  //           uid: "trackerName",
  //           name: "Bus"
  //         },
  //         {
  //           uid: "studentName",
  //           name: "Student Name"
  //         },
  //         {
  //           uid: "studentClass",
  //           name: "Class"
  //         },
  //         {
  //           uid: "rfid",
  //           name: "Ref. Id/Admission No"
  //         },
  //         {
  //           uid: "onwardPick",
  //           name: "Onward Pickup"
  //         },
  //         {
  //           uid: "onwardDrop",
  //           name: "Onward Drop"
  //         },
  //         {
  //           uid: "returnPick",
  //           name: "Return Pickup"
  //         },
  //         {
  //           uid: "returnDrop",
  //           name: "Return Drop"
  //         }
  //       ],
  //       activeColumns: [
  //         "date",
  //         "trackerName",
  //         "studentName",
  //         "studentClass",
  //         "rfid",
  //         "onwardPick",
  //         "onwardDrop",
  //         "returnPick",
  //         "returnDrop"
  //       ]
  //     },
  //     {
  //       icon: "",
  //       menu: "User Swipes",
  //       menu_description: "The report can be used to get the users swipes.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Wrong Swipe",
  //       menu_description:
  //         "The report can be used to get the wrong swipes by users.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Swipe Report",
  //       menu_description: "The report can be used to get the all swipes.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Swipe Deviation",
  //       menu_description:
  //         "The report can be used to get the deviation of card swipes by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "All Swipes",
  //       menu_description:
  //         "The report can be used to get the detailed card swipes by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "CTT",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Biometric",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "RFID Reports",
  //   description: "",
  //   order: 8,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "RFID Single Bus",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "RFID Multiple Bus",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Wrong Swipe",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Swipe Report",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Swipe Deviation",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "All Swipes",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Alerts",
  //   description: "",
  //   order: 13,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Harshbraking",
  //       menu_description:
  //         "The report can be used to get the detailed harsh braking alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Acceleration",
  //       menu_description:
  //         "The report can be used to get the detailed sudden acceleration alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Overspeed",
  //       menu_description:
  //         "The report can be used to get the detailed overspeed alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Trip Driven Deviation",
  //       menu_description:
  //         "The report can be used to get the detailed driver missed trip alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Acceleration",
  //       menu_description:
  //         "The report can be used to get the detailed sudden acceleration alerts by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Working Hours Breach",
  //       menu_description:
  //         "The report can be used to get the detailed employee working more then 12hrs.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Billing Reports",
  //   description: "",
  //   order: 2,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Billing Report",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"],
  //       columns: []
  //     }
  //   ]
  // },
  // {
  //   header: "UHF Reports",
  //   description: "",
  //   order: 3,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "UHF",
  //       menu_description:
  //         "The report can be used to get detailed UHF card swipes.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "UHF Records",
  //       menu_description:
  //         "The report can be used to get overview of UHF card swipes.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Announcement and Notification Reports",
  //   description: "",
  //   order: 4,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Announcement",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Notification",
  //       menu_description:
  //         "The Notifications report provides an overview of all notifications within the system, and can be used to monitor system activity during periods of load.",
  //       url: "/api/notifications/list",
  //       method: "post",
  //       filters: ["date"],
  //       columns: [
  //         {
  //           uid: "date",
  //           name: "Date"
  //         },
  //         {
  //           uid: "name",
  //           name: "Name"
  //         },
  //         {
  //           uid: "enrollNo",
  //           name: "Enroll NO"
  //         },
  //         {
  //           uid: "className",
  //           name: "Class"
  //         },
  //         {
  //           uid: "sectionName",
  //           name: "Section"
  //         },
  //         {
  //           uid: "uhfId",
  //           name: "UHF Id"
  //         },
  //         {
  //           uid: "msg",
  //           name: "Notification Content"
  //         }
  //       ],
  //       activeColumns: [
  //         "date","name","enrollNo","className","sectionName","uhfId","msg"
  //       ]
  //     }
  //   ]
  // },
  // {
  //   header: "Fuel Reports",
  //   description: "",
  //   order: 5,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Fuel Report",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Adhoc Reports",
  //   description: "",
  //   order: 6,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Adhoc Request",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },

  // {
  //   header: "Occupancy Reports",
  //   description: "",
  //   order: 9,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Occupancy",
  //       menu_description:
  //         "The report can be used to get the detailed about bus seat count and current capacity by each vehicle.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Analyses",
  //   description: "",
  //   order: 10,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "User Histories",
  //       menu_description:
  //         "Note: Past 7-day histories will be prepared by default (Without username).",
  //       url: "/api/user/histories",
  //       method: "POST",
  //       filters: [],
  //       columns: [
  //         {
  //           uid: "userName",
  //           name: "User Name"
  //         },
  //         {
  //           uid: "name",
  //           name: "Student Name"
  //         },
  //         {
  //           uid: "phone",
  //           name: "Phone Number"
  //         },
  //         {
  //           uid: "email",
  //           name: "Email"
  //         },
  //         {
  //           uid: "parentName",
  //           name: "Parent Name"
  //         },
  //         {
  //           uid: "userClass",
  //           name: "Class"
  //         },
  //         {
  //           uid: "section",
  //           name: "Section"
  //         },
  //         {
  //           uid: "uniqueId",
  //           name: "Stud ID"
  //         },
  //         {
  //           uid: "operation",
  //           name: "Operation"
  //         },
  //         {
  //           uid: "dateTime",
  //           name: "Date - Time"
  //         },
  //         {
  //           uid: "updatedBy",
  //           name: "Updated By"
  //         }
  //       ],
  //       activeColumns: ["userName", "name", "Phone"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Vehicle Unmapped users",
  //       menu_description:
  //         "This report lists users who have not been assigned to any vehicle within the system. It helps identify discrepancies or missing associations between users and vehicles.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Multiple Vehicle Mapped Users",
  //       menu_description:
  //         "This report highlights users who are linked to more than one vehicle. It is useful for tracking and resolving potential mapping conflicts or verifying multi-vehicle assignments.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Announcement History",
  //       menu_description:
  //         "This report provides a log of all announcements made within the system. It includes details such as the content, date, and recipients of each announcement for auditing or review purposes.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "On/Off Routes BPs",
  //       menu_description:
  //         "This report tracks Boarding Points (BPs) that are either on or off their designated routes. It helps monitor route adherence and ensure that BPs are being serviced as planned.",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "On/Off BPs Users",
  //       menu_description: "",
  //       url: "",
  //       method:
  //         "This report identifies users who are either associated with Boarding Points (BPs) that are currently active (on) or inactive (off).",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Hardware Reports",
  //   description: "",
  //   order: 11,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Hardware Report",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // },
  // {
  //   header: "Compliance Reports",
  //   description: "",
  //   order: 12,
  //   menus: [
  //     {
  //       icon: "",
  //       menu: "Vehicle Compliance",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     },
  //     {
  //       icon: "",
  //       menu: "Driver Compliance",
  //       menu_description: "",
  //       url: "",
  //       method: "",
  //       filters: ["date", "vehicle"]
  //     }
  //   ]
  // }
];
